<template>
  <MBaseModal @close="close">
    <template #title>学習室入室</template>
    <template #body>
      <div class="flex flex-col items-center text-center">
        <div class="px-2 py-3 mb-6 bg-primary-100 rounded-md">
          <p v-if="reEnter" class="mb-4 text-xs text-left text-gray-700">
            お帰りなさい♪最初の入室時に書いた目標に向けて、引き続き、がんばる場合はそのまま「入室」を押してね🌞新しい目標を書く場合は、この枠内の右上にある「×」を押してね👍
          </p>
          <p v-else class="mb-4 text-xs text-left text-gray-700">
            この学習室は、前向きに集中して勉強するための学習空間です😊ルールが守れない場合や他の人に迷惑がかかる場合は、強制退室となってしまいます💦
          </p>
          <p v-if="isSchoolRoom" class="text-xs text-left text-gray-700">
            この学習室は所属スクールで管理されている学習室です。所属するスクールのルールに従って利用してください
          </p>
          <p v-else-if="openRuleDetail" class="text-xs text-left text-gray-700">
            ①ビデオは「オン」、オーディオは「オフ」でお願いします。（デジタル教材での勉強の場合は入室時に申請をお願いします）<br />
            ②個人情報が特定できるものは写さないでください。<br />
            ③休憩時は一旦退室しましょう。（スマホを触る、仮眠をとる場合も含む）
          </p>
          <div v-else>
            <p class="text-xs text-left text-gray-700">
              ①ビデオは「オン」、オーディオは「オフ」で...
            </p>
            <div class="flex justify-center">
              <m-base-button
                text
                color="primary-500"
                @click="openRuleDetail = true"
              >
                詳細を表示
              </m-base-button>
            </div>
          </div>
        </div>
        <h6 class="mb-4 font-medium text-gray-700">
          この端末で学習室に入る場合
        </h6>
        <MButton v-if="isInLiff" @click="enter">入室</MButton>
        <a
          v-else
          :href="url"
          target="_blank"
          rel="noopener noreferrer"
          class="px-8 py-2 rounded-md bg-primary-500 font-medium text-white transition-all duration-300 hover:bg-primary-600"
        >
          入室
        </a>
        <span class="my-8 text-xs">または</span>
        <h6 class="mb-2 font-medium text-gray-700">
          別の端末で学習室に入る場合
        </h6>
        <p class="text-gray-700 text-sm mb-4">
          QRコードを読み取るか、ブラウザで以下のURLを開いてください
        </p>
        <qrcode-vue
          class="bg-gray-100 p-4 mb-4 rounded-md"
          :value="url"
          size="200"
          level="H"
        />
        <span class="text-gray-700 text-xs break-all">{{ url }}</span>
        <div
          class="flex items-center mt-6 mb-4 text-sm text-primary-500 transition-colors duration-300 cursor-pointer hover:text-primary-600"
          @click="showMeetingId"
        >
          <svg
            fill="currentColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
            class="w-6 h-6"
            viewBox="0 0 24 24"
          >
            <path
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
            />
          </svg>
          <span>上記の方法で入室できない方はこちら</span>
        </div>
        <div
          v-if="isDisplayMeetingId"
          class="flex flex-col items-center p-4 bg-primary-100 rounded-md text-gray-700 text-sm"
        >
          <div
            class="mb-2 border border-2 border-red-400 rounded-md p-3 bg-white"
          >
            <div class="flex items-center justify-center text-red-500 mb-2">
              <m-icon type="warning" size="6" />
              <div>【こちらの入室方法は緊急時のみご利用ください】</div>
            </div>
            <div>
              こちらの入室方法で学習室に入ることはできますが、学習ログが残りません。通常のブラウザ版（LINE
              以外から入室）での入室方法は、<a
                href="https://mingaku.net/guide-student/browser-usage"
                target="_blank"
                rel="noopener noreferrer"
                class="text-primary-500 hover:text-primary-600"
                >マニュアル</a
              >をご参照ください。
            </div>
          </div>
          <p class="mb-2">
            以下のミーティングIDをZoomアプリに直接入力して参加してください
          </p>
          <p class="mb-2">
            ミーティングID: <span class="font-medium">{{ meetingId }}</span>
          </p>
          <p>パスワード: <span class="font-medium">mWSeg-2K</span></p>
        </div>
      </div>
    </template>
  </MBaseModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MBaseButton from "@/components/MBaseButton.vue";
import MButton from "@/components/form/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import liff from "@line/liff/dist/lib";
import QrcodeVue from "qrcode.vue";
import store from "@/store";

@Options({
  components: {
    MBaseModal,
    MBaseButton,
    MButton,
    MIcon,
    QrcodeVue
  },
  emits: ["close"],
  props: {
    url: String,
    isSchoolRoom: Boolean,
    reEnter: Boolean
  }
})
export default class MRJoinModal extends Vue {
  url = "";
  isDisplayMeetingId = false;
  isSchoolRoom = false;
  reEnter = false;
  openRuleDetail = false;

  get meetingId(): string {
    const dividedUrlTexts = this.url.split("?")[0].split("/");
    if (dividedUrlTexts.length <= 1) {
      return "";
    }
    return dividedUrlTexts[dividedUrlTexts.length - 1];
  }

  get isInLiff(): boolean {
    return store.state.isInLiff;
  }

  showMeetingId() {
    this.isDisplayMeetingId = true;
  }

  enter() {
    if (!this.isInLiff) {
      return;
    }

    try {
      liff.openWindow({
        url: this.url,
        external: true
      });
    } catch (e) {
      alert(e.message);
    }
    window.close();
  }

  close() {
    this.$emit("close");
  }
}
</script>
