<template>
  <MBaseModal @close="close">
    <template #title>入室する学習室を選択</template>
    <template #body>
      <div class="w-full flex flex-col items-center text-center">
        <div v-if="!roomExists">
          <p class="mb-4 font-medium">学習室がありません</p>
        </div>
        <div v-for="room in rooms" v-else :key="room.ref.id" class="w-full">
          <div
            v-if="
              !room.data.classroomIdsOfTutor ||
              room.data.classroomIdsOfTutor.includes(classroomId)
            "
            class="p-4 mb-6 flex flex-col items-center bg-primary-100 rounded-md"
          >
            <p class="mb-4 font-medium">{{ room.data.name }}</p>
            <m-button @click="select(room.ref.id)"> 入室 </m-button>
          </div>
        </div>
      </div>
    </template>
  </MBaseModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import { Room } from "@/entities/room";
import store from "@/store";

@Options({
  components: {
    MBaseModal,
    MButton
  },
  emits: ["close", "select"],
  props: {
    rooms: Array
  }
})
export default class MRoomSelectModal extends Vue {
  roomExists = false;
  get classroomId(): string {
    if (!store.state.role) {
      return "";
    }
    //storeから生徒のclassroomIdを取得
    return store.state.role.data.ref.parent.path.split("/")[3];
  }
  rooms: Room[] = [];

  select(roomId: string) {
    this.$emit("select", roomId);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.roomExists = this.rooms.some(room => {
      return (
        !room.data.classroomIdsOfTutor ||
        room.data.classroomIdsOfTutor.includes(this.classroomId)
      );
    });
  }
}
</script>
