
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MBaseButton from "@/components/MBaseButton.vue";
import MButton from "@/components/form/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import liff from "@line/liff/dist/lib";
import QrcodeVue from "qrcode.vue";
import store from "@/store";

@Options({
  components: {
    MBaseModal,
    MBaseButton,
    MButton,
    MIcon,
    QrcodeVue
  },
  emits: ["close"],
  props: {
    url: String,
    isSchoolRoom: Boolean,
    reEnter: Boolean
  }
})
export default class MRJoinModal extends Vue {
  url = "";
  isDisplayMeetingId = false;
  isSchoolRoom = false;
  reEnter = false;
  openRuleDetail = false;

  get meetingId(): string {
    const dividedUrlTexts = this.url.split("?")[0].split("/");
    if (dividedUrlTexts.length <= 1) {
      return "";
    }
    return dividedUrlTexts[dividedUrlTexts.length - 1];
  }

  get isInLiff(): boolean {
    return store.state.isInLiff;
  }

  showMeetingId() {
    this.isDisplayMeetingId = true;
  }

  enter() {
    if (!this.isInLiff) {
      return;
    }

    try {
      liff.openWindow({
        url: this.url,
        external: true
      });
    } catch (e) {
      alert(e.message);
    }
    window.close();
  }

  close() {
    this.$emit("close");
  }
}
