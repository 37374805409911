
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import { Room } from "@/entities/room";
import store from "@/store";

@Options({
  components: {
    MBaseModal,
    MButton
  },
  emits: ["close", "select"],
  props: {
    rooms: Array
  }
})
export default class MRoomSelectModal extends Vue {
  roomExists = false;
  get classroomId(): string {
    if (!store.state.role) {
      return "";
    }
    //storeから生徒のclassroomIdを取得
    return store.state.role.data.ref.parent.path.split("/")[3];
  }
  rooms: Room[] = [];

  select(roomId: string) {
    this.$emit("select", roomId);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.roomExists = this.rooms.some(room => {
      return (
        !room.data.classroomIdsOfTutor ||
        room.data.classroomIdsOfTutor.includes(this.classroomId)
      );
    });
  }
}
