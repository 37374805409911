
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["close"],
  props: {
    bodyBackgroundColor: String,
    height: String
  }
})
export default class MBaseModal extends Vue {
  bodyBackgroundColor = "";
  height = "normal";

  close() {
    this.$emit("close");
  }
}
