<template>
  <div class="flex flex-col w-full">
    <label :for="name" class="leading-7 text-sm text-gray-600">
      {{ label }}
    </label>
    <div class="relative transition-all duration-300 hover:text-primary-500">
      <select
        class="w-full rounded-md bg-white appearance-none py-2 pl-3 pr-10 focus:outline-none hover:border-primary-600 cursor-pointer"
        :name="name"
        :value="value"
        @change="$emit('change', $event.target.value)"
      >
        <option disabled value="">
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :key="keyOf(option)"
          :value="valueOf(option)"
          :selected="value == valueOf(option)"
        >
          {{ textOf(option) }}
        </option>
      </select>
      <span
        class="absolute right-2 top-0 h-full w-6 text-center pointer-events-none flex items-center justify-center text-primary-500"
      >
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          class="w-4 h-4"
          viewBox="0 0 24 24"
        >
          <path d="M6 9l6 6 6-6"></path>
        </svg>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["change"],
  props: {
    label: String,
    name: String,
    placeholder: String,
    options: Array,
    value: [Object, String, Number],
    valueKey: String,
    textKey: String,
    key: String
  }
})
export default class MSelectBox extends Vue {
  label = "";
  name = "";
  placeholder = "";
  options = [];
  value: any;
  valueKey = "";
  textKey = "";
  key = "";

  valueOf(option: any) {
    if (this.valueKey.length === 0) {
      return option;
    }
    return option[this.valueKey];
  }

  textOf(option: any) {
    if (this.textKey.length === 0) {
      return option;
    }
    return option[this.textKey];
  }

  keyOf(option: any) {
    if (this.key.length === 0) {
      return option;
    }
    return option[this.key];
  }
}
</script>
