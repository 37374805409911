
import { Options, Vue } from "vue-class-component";
import { getRegistrant } from "@/api/room";
import MCommentPanel from "@/components/MCommentPanel.vue";
import MSubjectsInput from "@/components/MSubjectsInput.vue";
import MRJoinModal from "@/components/MRJoinModal.vue";
import MRoomSelectModal from "@/components/MRoomSelectModal.vue";
import MButton from "@/components/form/MButton.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import { LearningSubject } from "@/entities/learning";
import store from "@/store";
import { StudentGrade } from "@/entities/student";
import { Room } from "@/entities/room";
import { saveErrorLog } from "@/api/error";
import { RegistrantCameraStatus } from "@/entities/registrant";

@Options({
  components: {
    MCommentPanel,
    MSubjectsInput,
    MRJoinModal,
    MRoomSelectModal,
    MButton,
    MTextArea,
    MTextField,
    MSelectBox
  },
  watch: {
    learningLoading: function () {
      this.onUpdateLoading();
    },
    roomLoaded: function () {
      this.onUpdateLoading();
    }
  }
})
export default class EnterForm extends Vue {
  goal = "";
  subjects: LearningSubject[] = [];
  withCameraStatus = false;
  cameraStatus: RegistrantCameraStatus = "use_device";
  cameraStatusOptions: { value: RegistrantCameraStatus; text: string }[] = [
    {
      value: "use_device",
      text: "端末を使った学習（映像授業/調べ学習など）"
    },
    {
      value: "connection_practice",
      text: "接続練習のため"
    },
    {
      value: "device_defect",
      text: "端末の不調（Wifiやカメラ故障など）"
    },
    {
      value: "other",
      text: "その他"
    }
  ];
  cameraComment = "";
  joinUrl = "";
  showSelectModal = false;
  showJoinModal = false;
  isSchoolRoom = false;
  reEnter = false;
  greeting = "";

  get learningLoading(): boolean {
    return store.state.learningLoading;
  }

  get roomLoaded(): boolean {
    return store.state.roomLoaded;
  }

  get rooms(): Room[] {
    return store.state.rooms;
  }

  get studentGrade(): StudentGrade {
    return store.state.student?.data.grade ?? "その他";
  }

  get validForm(): boolean {
    return (
      this.goal.length > 0 &&
      this.subjects.length > 0 &&
      !!(
        !this.withCameraStatus ||
        this.cameraStatus !== "other" ||
        this.cameraComment
      )
    );
  }

  get dynamicButtonCss() {
    if (this.validForm) {
      return {
        "bg-primary-500": true,
        "hover:bg-primary-600": true,
        "cursor-pointer": true
      };
    } else {
      return {
        "bg-gray-300": true,
        "cursor-default": true
      };
    }
  }

  changeSubjects(subjects: LearningSubject[]) {
    this.subjects = [...subjects];
  }

  async onTapEnterButton() {
    if (this.rooms.length === 0) {
      return;
    } else if (this.rooms.length === 1) {
      this.enterRoom(this.rooms[0].ref.id);
    } else {
      this.showSelectModal = true;
    }
  }

  async enterRoom(roomId: string) {
    if (!store.state.student || !this.validForm || this.rooms.length === 0) {
      return;
    }

    const matchRooms = this.rooms.filter(room => room.ref.id === roomId);
    if (matchRooms.length === 0) {
      return;
    }
    this.isSchoolRoom = !!matchRooms[0].data.school;

    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "学習室確認中...");

    this.showSelectModal = false;

    try {
      const url = await getRegistrant(
        store.state.student.ref,
        roomId,
        this.goal,
        this.subjects,
        this.greeting,
        this.withCameraStatus ? this.cameraStatus : undefined,
        this.withCameraStatus && this.cameraStatus == "other"
          ? this.cameraComment
          : undefined
      );
      this.joinUrl = url;
      this.reEnter = false;
      this.showJoinModal = true;
    } catch (e) {
      if (e.code === "not-found") {
        alert(
          "入室しようとしている学習室が見つかりませんでした。\nアプリを開き直した上で再度入室フォームを送信してください。"
        );
      } else if (e.code === "permission-denied") {
        alert("入室しようとしている学習室に参加する権限がありません");
      } else if (e.code === "already-exists") {
        alert(
          "同じユーザーが既に学習室に参加しています。\n身に覚えの無い場合は管理人に連絡してください。"
        );
      } else {
        alert(`入室フォームの送信に失敗しました\n\n${e}`);
        await saveErrorLog(
          store.state.student,
          e.code,
          e.message,
          "Failed to send enter form"
        );
      }
    }

    store.commit("SET_LOADING", false);
    store.commit("SET_LOAD_TEXT", "");
  }

  async created() {
    const joinUrl = this.$route.query.join;
    const pwd = this.$route.query.pwd;
    if (
      joinUrl &&
      typeof joinUrl === "string" &&
      pwd &&
      typeof pwd === "string"
    ) {
      this.joinUrl = `${joinUrl}&pwd=${pwd}`;
      this.reEnter = true;
      this.showJoinModal = true;
    }
    this.onUpdateLoading();
  }

  onUpdateLoading() {
    if (store.state.roomLoaded && !store.state.learningLoading) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      const learningTimers = store.state.learnings.filter(learning => {
        return learning.data.meta && learning.data.meta.timer;
      });
      if (learningTimers.length > 0) {
        alert("現在タイムキーパーで学習時間を記録しています");
        this.$router.replace(`/learning/${learningTimers[0].ref.id}/timer`);
      } else if (store.state.rooms.length === 0) {
        alert(
          "📣この時間帯は、タイムキーパーを活用した学習時間となります⏱\n「OK」ボタンを押して、タイムキーパーで学習を開始しよう✏️📗🔥"
        );
        this.$router.replace("/learning_start");
      }
    } else {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "学習室検索中...");
    }
  }
}
