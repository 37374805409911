<template>
  <div
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-full md:w-4/5 mx-6 md:mx-auto my-auto max-w-6xl">
      <!--content-->
      <div
        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none"
      >
        <!--header-->
        <div
          class="flex items-center justify-between py-2 px-4 md:py-5 md:px-5 border-b border-solid border-gray-300 rounded-t"
        >
          <h3 class="text-lg md:text-2xl font-bold">
            <slot name="title" />
          </h3>
          <button
            class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            @click="close"
          >
            <span
              class="bg-transparent text-gray-500 h-6 w-6 text-xl block outline-none focus:outline-none"
            >
              ×
            </span>
          </button>
        </div>
        <!--body-->
        <div
          class="relative p-6 flex-auto modal-height overflow-y-scroll"
          :class="{
            [bodyBackgroundColor]: bodyBackgroundColor,
            [height]: height
          }"
        >
          <slot name="body" />
        </div>
        <div
          class="flex items-center justify-end px-4 md:px-6 py-2 md:py-4 border-t border-solid border-gray-300 rounded-b"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-20 bg-black" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["close"],
  props: {
    bodyBackgroundColor: String,
    height: String
  }
})
export default class MBaseModal extends Vue {
  bodyBackgroundColor = "";
  height = "normal";

  close() {
    this.$emit("close");
  }
}
</script>

<style lang="scss" scoped>
.modal-height {
  height: 60vh;
  &.normal {
    height: 60vh;
  }
  &.tall {
    height: 80vh;
  }
}
</style>
